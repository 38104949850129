<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !roleList }">
		<v-preloader v-if="!roleList" :message="errorMessage"></v-preloader>

		<div class="container" v-if="roleList">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['UserManagementMain', 'UserManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('titles.registration_user') }}</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.role_in_system') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.role_id
																? 'input--error'
																: '',
														]"
													>
														<multiselect
															class="select__status"
															v-model="roleModel"
															:options="roleList"
															label="description"
															:placeholder="$t('labels.select_role')"
															><!--name="role"-->
															<template slot="option" slot-scope="{ option }">{{
																option.description
															}}</template>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.description
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.role_id"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.role_id"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line" v-if="_getRegionList && roleModel.role_region">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.regions') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.region_id
																? 'input--error'
																: '',
														]"
													>
														<multiselect
															class="select__status"
															v-model="regionModel"
															:options="_getRegionList"
															:placeholder="$t('labels.select_region')"
															:multiple="true"
															:close-on-select="false"
															:clear-on-select="false"
															:preserve-search="true"
															track-by="id"
															label="name"
															required=""
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.name
															}}</template>
															<template slot="option" slot-scope="{ option }">
																<div class="select__check">
																	<div class="select__check--name">
																		{{ option.name }}
																	</div>
																</div>
															</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.region_id"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.region_id"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_name') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.first_name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="first_name"
															:placeholder="$t('headers.user_name')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.first_name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.first_name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_surname') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.last_name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="last_name"
															:placeholder="$t('headers.user_surname')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.last_name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.last_name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_patronymic') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.middle_name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															name="middle_name"
															:placeholder="$t('headers.user_patronymic')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.middle_name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.middle_name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.user_role') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.job
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															name="job"
															:placeholder="$t('headers.user_role')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.job"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.job"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.name_company') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.name_company
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															name="name_company"
															:placeholder="$t('headers.name_company')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.name_company"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.name_company"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.iin') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.iin_bin
																? 'input--error'
																: '',
														]"
													>
														<TheMask
															v-model="iin_bin"
															:masked="true"
															:name="'iin_bin'"
															:placeholder="$t('headers.iin')"
															:mask="['### ### ### ###']"
															type="text"
															class="input-linck"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.iin_bin"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.iin_bin"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.email') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.email
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="email"
															:placeholder="$t('headers.email')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.email"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.email"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('site.registration.phone') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<div
															class="form__block--input"
															:class="[
																errorMessage.messages && errorMessage.messages.phone
																	? 'input--error'
																	: '',
															]"
														>
															<TheMask
																:value="phone"
																:placeholder="'+7 (_ _ _)  _ _ _  _ _  _ _'"
																:mask="['+7 (###) ### ## ##']"
																:masked="true"
																name="phone"
																type="tel"
																class="input-linck"
																v-on:input="phone = $event"
															/>
														</div>
														<div
															class="input-required"
															v-if="errorMessage.messages && errorMessage.messages.phone"
														>
															<p
																v-for="(massage, massageInd) in errorMessage.messages.phone"
																:key="massageInd"
															>
																{{ massage }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="input__row" v-if="$store.getters.userPermission(['change_user'])">
												<button class="input-btn form__line--btn">
													{{ $t('commands.registration') }}
												</button>
											</div>

											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__block--line form__messages"
														v-if="errorMessage.text"
														:class="{
															'form__messages--error': errorMessage.status != 200,
															'form__messages--success': errorMessage.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { TheMask } from 'vue-the-mask'
import Multiselect from 'vue-multiselect'

import { api } from '@/boot/axios'

export default {
	name: 'UserManagementAdd',
	components: {
		TheMask,
		Multiselect,
	},
	validations: {},
	data() {
		return {
			applications: null,
			iin_bin: '',
			phone: '',
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},

			message: '',

			roleModel: [],
			roleList: null,
			regionModel: [],

			password: '',
			comparePassword: '',
			inputType: false,
		}
	},
	computed: {
		...mapGetters(['_getRegionList']),
	},
	methods: {
		...mapActions(['_fetchRegionList']),
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			if (evt.target.iin_bin)
				evt.target.iin_bin.value = evt.target.iin_bin.value.replaceAll(' ', '')
			if (evt.target.phone) evt.target.phone.value = this.phone.replaceAll(/\D/g, '')
			const formData = new FormData(evt.target)
			formData.append('role_id', this.roleModel.id)
			if (this.regionModel) {
				this.regionModel.find((element, index) => {
					formData.append('regions[' + index + ']', element.id)
				})
			}
			api
				.post('user/create', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.errorMessage.status = 200
						this.errorMessage.text = response.data.message
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/users' })
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message

						this.message.status = 422
						this.message.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
			//}
		},
		apiGetUserList() {
			api
				.get('user/role/list')
				.then((response) => {
					if (response.data) {
						this.roleList = response.data
						response.data.find((element) => {
							element.accesses.find((accesse) => {
								if (accesse == 'role_region') element.role_region = true
							})
						})

						this._fetchRegionList()
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	beforeMount() {
		this.apiGetUserList()
		this._fetchRegionList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>

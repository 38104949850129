var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap__content",class:{ 'wrap__content--preloader': !_vm.roleList }},[(!_vm.roleList)?_c('v-preloader',{attrs:{"message":_vm.errorMessage}}):_vm._e(),(_vm.roleList)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3"},[_c('v-sidebar',{attrs:{"active":['UserManagementMain', 'UserManagement']}})],1),_c('div',{staticClass:"col-xl-10 col-lg-9"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"card__content"},[_c('div',{staticClass:"card__content-header"},[_c('div',{staticClass:"content__title"},[_c('div',{staticClass:"content__page--back"},[_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{attrs:{"width":"21","height":"21","viewBox":"0 0 21 21","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M13.125 5.25l-5.25 5.25 5.25 5.25","stroke":"#52A5FC","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('div',{staticClass:"content__title--element"},[_c('div',{staticClass:"content__title--text"},[_vm._v(_vm._s(_vm.$t('titles.registration_user')))])])])]),_c('div',{staticClass:"card__content--body"},[_c('form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.role_in_system')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.role_id
															? 'input--error'
															: '',
													]},[_c('multiselect',{staticClass:"select__status",attrs:{"options":_vm.roleList,"label":"description","placeholder":_vm.$t('labels.select_role')},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_vm._v(_vm._s(option.description))]}},{key:"singleLabel",fn:function({ option }){return [_vm._v(_vm._s(option.description))]}}],null,false,1677554774),model:{value:(_vm.roleModel),callback:function ($$v) {_vm.roleModel=$$v},expression:"roleModel"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('components.not_found')))]),_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('components.not_found')))])],2)],1),(_vm.errorMessage.messages && _vm.errorMessage.messages.role_id)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.role_id),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),(_vm._getRegionList && _vm.roleModel.role_region)?_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.regions')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.region_id
															? 'input--error'
															: '',
													]},[_c('multiselect',{staticClass:"select__status",attrs:{"options":_vm._getRegionList,"placeholder":_vm.$t('labels.select_region'),"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"track-by":"id","label":"name","required":""},scopedSlots:_vm._u([{key:"singleLabel",fn:function({ option }){return [_vm._v(_vm._s(option.name))]}},{key:"option",fn:function({ option }){return [_c('div',{staticClass:"select__check"},[_c('div',{staticClass:"select__check--name"},[_vm._v(" "+_vm._s(option.name)+" ")])])]}}],null,false,1140050193),model:{value:(_vm.regionModel),callback:function ($$v) {_vm.regionModel=$$v},expression:"regionModel"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('components.not_found')))])],2)],1),(_vm.errorMessage.messages && _vm.errorMessage.messages.region_id)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.region_id),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]):_vm._e(),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.user_name')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.first_name
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"first_name","placeholder":_vm.$t('headers.user_name')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.first_name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.first_name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.user_surname')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.last_name
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"last_name","placeholder":_vm.$t('headers.user_surname')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.last_name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.last_name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.user_patronymic')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.middle_name
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","name":"middle_name","placeholder":_vm.$t('headers.user_patronymic')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.middle_name)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.middle_name),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.user_role')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.job
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","name":"job","placeholder":_vm.$t('headers.user_role')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.job)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.job),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.name_company')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.name_company
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","name":"name_company","placeholder":_vm.$t('headers.name_company')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.name_company)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.name_company),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.iin')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.iin_bin
															? 'input--error'
															: '',
													]},[_c('TheMask',{staticClass:"input-linck",attrs:{"masked":true,"name":'iin_bin',"placeholder":_vm.$t('headers.iin'),"mask":['### ### ### ###'],"type":"text"},model:{value:(_vm.iin_bin),callback:function ($$v) {_vm.iin_bin=$$v},expression:"iin_bin"}})],1),(_vm.errorMessage.messages && _vm.errorMessage.messages.iin_bin)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.iin_bin),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('headers.email')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input",class:[
														_vm.errorMessage.messages && _vm.errorMessage.messages.email
															? 'input--error'
															: '',
													]},[_c('input',{staticClass:"input-linck",attrs:{"type":"text","required":"","name":"email","placeholder":_vm.$t('headers.email')}})]),(_vm.errorMessage.messages && _vm.errorMessage.messages.email)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.email),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"},[_c('div',{staticClass:"form__line--title"},[_vm._v(_vm._s(_vm.$t('site.registration.phone')))])]),_c('div',{staticClass:"form__line--right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[_c('div',{staticClass:"form__line--input"},[_c('div',{staticClass:"form__block--input",class:[
															_vm.errorMessage.messages && _vm.errorMessage.messages.phone
																? 'input--error'
																: '',
														]},[_c('TheMask',{staticClass:"input-linck",attrs:{"value":_vm.phone,"placeholder":'+7 (_ _ _)  _ _ _  _ _  _ _',"mask":['+7 (###) ### ## ##'],"masked":true,"name":"phone","type":"tel"},on:{"input":function($event){_vm.phone = $event}}})],1),(_vm.errorMessage.messages && _vm.errorMessage.messages.phone)?_c('div',{staticClass:"input-required"},_vm._l((_vm.errorMessage.messages.phone),function(massage,massageInd){return _c('p',{key:massageInd},[_vm._v(" "+_vm._s(massage)+" ")])}),0):_vm._e()])])])])]),_c('div',{staticClass:"form__line"},[_c('div',{staticClass:"form__line--left"}),_c('div',{staticClass:"form__line--right"},[(_vm.$store.getters.userPermission(['change_user']))?_c('div',{staticClass:"input__row"},[_c('button',{staticClass:"input-btn form__line--btn"},[_vm._v(" "+_vm._s(_vm.$t('commands.registration'))+" ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5"},[(_vm.errorMessage.text)?_c('div',{staticClass:"form__block--line form__messages",class:{
														'form__messages--error': _vm.errorMessage.status != 200,
														'form__messages--success': _vm.errorMessage.status == 200,
													}},[_c('label',{staticClass:"form__block--input"},[_c('span',{staticClass:"form__block--title"},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])])]):_vm._e()])])])])])])])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }